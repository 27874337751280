<template>
  <div class="header-menu">
    <div class="main">
      <nuxt-link to="/" class="home-link"
        ><img
          src="https://video.reta-inc.com/image/default/77E201027F50449CA38EF4BC0E7F8537-6-2.jpg"
          alt="DG.hire"
          class="img"
      /></nuxt-link>
      <ul class="menu">
        <template v-for="(menu, index) in menus" :key="index">
          <li
            class="menu__submenu"
            v-if="menu.children?.length"
            :class="{ active: route.fullPath.startsWith(`/${menu.name}`) }"
            @click="onPrimaryMenuActive(menu)"
            @mouseover="onPrimaryMenuActive(menu)"
            @mouseleave="onPrimaryMenuInactive"
          >
            <div class="menu__submenu-title">{{ $t(menu.title) }}</div>
            <i class="icon-arrow-down"></i>
            <component
              v-if="componentMap[menu.name]"
              v-show="showSubMenuName === menu.name"
              :is="componentMap[menu.name]"
              :menuList="menu.children"
              @onMenuClick="onMenuClick"
            />
            <img
              v-if="menu.title === '资源中心'"
              class="hot"
              src="https://video.reta-inc.com/image/default/08F2E583641D40E39B005ABD38E7FECF-6-2.png"
              alt="hot"
            />
          </li>
          <li class="menu__item" v-else :class="{ active: route.path.includes(menu.url!) }" @click="onMenuClick(menu)">
            {{ $t(menu.title) }}
          </li>
        </template>
      </ul>
      <div class="change_lang" style="margin-left: auto; display: flex; align-items: center">
        <a-select class="a_select" v-model="locale" @change="changeLang">
          <template #prefix>
            <img :src="langIcon" alt="lang" />
          </template>
          <a-option value="zh-CN">{{ $t('中文') }}</a-option>
          <a-option value="en-US">{{ $t('英文') }}</a-option>
        </a-select>
        <a-button
          v-if="!mobile"
          type="outline"
          href="https://e.dghire.com/b/login"
          style="margin-left: auto"
          class="login"
        >
          {{ $t('登录') }}
        </a-button>
      </div>

      <div class="btn-group">
        <nuxt-link
          class="free-trial"
          :to="getLeadsLink({ from: '导航栏-免费试用', path: route.fullPath, type: 'freeTrial' })"
        >
          {{ $t('免费试用') }}
        </nuxt-link>
        <a-button class="nav" :class="{ active: isDropdownOpened }" @click="handleOpenMenu">
          <template #icon>
            <icon-menu style="stroke-width: 5; font-size: 14px" />
          </template>
          <template #default>导航</template>
        </a-button>
        <a-drawer
          :visible="isDropdownOpened"
          :header="false"
          :footer="false"
          @cancel="isDropdownOpened = false"
          placement="top"
          height="auto"
          popup-container=".header-menu"
        >
          <a-collapse accordion @change="handleCollapseChange">
            <a-collapse-item v-for="(menu, index) in menus" :key="index" :header="menu.title">
              <template v-if="menu.children?.length" #extra>
                <icon-down size="12" />
              </template>
              <div class="menu-list">
                <nuxt-link class="menu-link" :to="submenu.url" v-for="submenu in menu.children" :key="submenu.name">{{
                  submenu.title
                }}</nuxt-link>
              </div>
            </a-collapse-item>
          </a-collapse>
        </a-drawer>
      </div>
    </div>

    <DropdownMenu ref="dropdownMenuRef" />
  </div>
</template>
<script lang="ts" setup>
import ProductMenu from '@/components/ProductMenu.vue';
import SolutionMenu from '@/components/SolutionMenu.vue';
import InfoMenu from '@/components/InfoMenu.vue';
import ResourceMenu from '@/components/ResourceMenu.vue';
import AboutMenu from '@/components/AboutMenu.vue';
import CustomerCaseMenu from '@/components/CustomerCaseMenu.vue';
import menus, { IMenuItem } from '@/common/menu';
import { getLeadsLink, isMobile } from '@/common/utils';
import DropdownMenu from './DropdownMenu.vue';
import { useI18n } from 'vue-i18n';
import langIcon from '@/assets/icons/lang.svg';

const { locale } = useI18n();
const mobile = ref(false);
const route = useRoute();

const changeLang = () => {
  const { value } = locale;
  localStorage.setItem('lang', value);
  const element = document.getElementById('staring_bubbleButton');
  if (element) {
    if (value === 'zh-CN') {
      element.style.display = 'block';
      return;
    }
    element.style.display = 'none';
  }
};

const componentMap: Record<string, any> = {
  product: ProductMenu,
  solution: SolutionMenu,
  info: InfoMenu,
  resource: ResourceMenu,
  about: AboutMenu,
  showcase: CustomerCaseMenu,
};

onMounted(() => {
  mobile.value = isMobile();
});

const showSubMenuName = ref('');
function onPrimaryMenuActive(menu: IMenuItem) {
  if (mobile.value && showSubMenuName.value) {
    onPrimaryMenuInactive();
    return;
  }
  showSubMenuName.value = menu.name;
}
function onPrimaryMenuInactive() {
  showSubMenuName.value = '';
}

const isDropdownOpened = ref(false);
async function onMenuClick(menu: IMenuItem) {
  useLogger().track('menu', {
    source: menu.title,
  });
  await navigateTo(menu.url!);
  isDropdownOpened.value = false;
}

const router = useRouter();
router.afterEach(() => {
  isDropdownOpened.value = false;
});

async function handleCollapseChange(indexList: (string | number)[]) {
  if (typeof indexList[0] === 'number' && !menus[indexList[0]].children?.length) {
    await navigateTo({
      path: menus[indexList[0]].url!,
    });
  }
}

const dropdownMenuRef = ref<typeof DropdownMenu>();
function handleOpenMenu() {
  if (dropdownMenuRef.value?.transMenus.some((n: any) => n.visible)) {
    dropdownMenuRef.value?.handleCancelDrawer(true);
    setTimeout(() => {
      isDropdownOpened.value = !isDropdownOpened.value;
    }, 300);
    return;
  }
  isDropdownOpened.value = !isDropdownOpened.value;
}

watch(isDropdownOpened, newVal => {
  if (newVal) {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = 'auto';
  }
});
</script>
<style lang="less" scoped>
.header-menu {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 11;
  overflow: unset !important;

  @media screen and (min-width: 800px) {
    :deep(.arco-drawer-container) {
      display: none;
    }
    :deep(.a_select) {
      width: fit-content;
      max-width: 130px;
      margin-right: 10px;
      background: none;
    }
    .main {
      display: flex;
      align-items: center;
      width: 1280px;
      height: 70px;
      margin: 0 auto;
      min-width: 1280px;
      padding: 0 32px;

      a.home-link {
        flex-shrink: 0;
        margin-right: 70px;
        width: 132px;
        height: 70px;
        overflow: hidden;
        .img {
          width: 100%;
          margin-top: -5px;
        }
      }

      .btn-group {
        margin-left: 12px;

        .free-trial {
          display: block;
          width: 80px;
          height: 36px;
          background: #165dff;
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          line-height: 36px;
          color: #ffffff;
          text-align: center;
          text-decoration: none;
        }
        .nav {
          display: none;
        }
      }

      .arco-btn {
        border-radius: 4px;

        & + .arco-btn {
          margin-left: 12px;
        }
      }
    }

    ul,
    li {
      list-style-type: none;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 0;
    }

    .nav {
      display: none;
    }

    .menu {
      display: flex;
      font-size: 16px;
      line-height: 22px;
      color: #1d2129;

      &__submenu,
      &__item {
        padding: 0 10px;
        height: 42px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 16px;
        }

        &.active,
        &:hover {
          font-weight: 500;
          color: #165dff;
        }
      }

      &__submenu {
        position: relative;

        .icon-arrow-down {
          display: block;
          width: 12px;
          height: 12px;
          background: url('@/assets/icons/arrow-down.svg') no-repeat;
          background-size: contain;
          margin-left: 8px;
        }

        @keyframes hot-move {
          0% {
            transform: scale(0);
          }

          20% {
            transform: scale(1);
          }

          25%,
          35%,
          45% {
            transform: rotate(-5deg);
          }

          30%,
          40%,
          50% {
            transform: rotate(5deg);
          }
          55%,
          100% {
            transform: rotate(0deg);
          }
        }

        .hot {
          width: 25px;
          position: absolute;
          top: -2px;
          right: 8px;
          transform-origin: 0 100%;
          animation: hot-move 4s linear infinite both;
        }
      }
    }

    .login {
      height: 36px;
    }
  }

  @media screen and (max-width: 800px) {
    .change_lang {
      display: none !important;
    }
    :deep(.arco-drawer-container) {
      width: 100vw;
      height: calc(100vh - 60px);
      top: 60px;
      .arco-drawer-mask {
        backdrop-filter: blur(2px);
      }
      .arco-drawer {
        border-radius: 0 0 16px 16px;
      }
      .arco-drawer-body {
        padding: 0px 32px 10px;
        max-height: 500px;
        overflow-y: auto;
        .arco-collapse {
          border: 0;
          .arco-collapse-item {
            border-bottom-color: #f2f3f5;
            -webkit-tap-highlight-color: transparent;
            .arco-collapse-item-header-title {
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              color: #1d2129;
            }
            &.arco-collapse-item-active {
              .arco-icon-down {
                transform: rotate(180deg);
              }
            }
            .arco-collapse-item-header {
              height: 48px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0;
              border: 0;
              .arco-icon-hover {
                display: none;
              }
            }
            .arco-collapse-item-content-box {
              padding: 0;
            }
            .arco-collapse-item-content {
              padding: 0;
              background: #fff;
              .menu-list {
                display: flex;
                flex-direction: column;
                .menu-link {
                  height: 48px;
                  line-height: 48px;
                  font-weight: 400;
                  font-size: 14px;
                  color: #4e5969;
                  display: block;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
    .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding: 0 16px;
      background: #fff;
      position: relative;
      z-index: 12;

      a.home-link {
        flex-shrink: 0;
        margin-right: 70px;
        width: 132px;
        height: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        .img {
          width: 120px;
        }
      }

      .arco-btn {
        border-radius: 4px;

        &:first-child {
          margin-right: 12px;
        }
      }

      .login {
        display: none;
      }

      .btn-group {
        display: flex;
        align-items: center;

        .free-trial {
          display: block;
          width: 80px;
          height: 32px;
          background: rgba(22, 93, 255, 0.1);
          border-radius: 4px;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
          color: #165dff;
          text-align: center;
          text-decoration: none;
          margin-right: 12px;
        }
        .nav {
          display: block;
          &.active {
            background: #165dff;
            color: #fff;
          }
        }

        :deep(.arco-dropdown-open) {
          background: #165dff;
          color: #fff;
        }
      }

      :deep(.arco-btn-size-medium:not(.arco-btn-only-icon) .arco-btn-icon) {
        margin-right: 4px;
      }

      .nav {
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        color: #165dff;
        padding: 8px;
        border: 1px solid #165dff;
        background: #fff;
      }
    }

    .menu {
      display: none;
    }

    .menu_icon {
      margin-left: 16px;
    }
  }
}

@media screen and (min-width: 800px) {
  .header-menu {
    min-width: 1344px;
  }
}
</style>
<style lang="less">
.arco-trigger-popup .arco-trigger-content {
  .arco-dropdown-list-wrapper {
    max-height: unset;
  }

  .inner_menu {
    // display: flex;
    box-sizing: border-box;
    padding: 0 32px;
    width: 100vw;
    font-size: 16px;
    line-height: 22px;
    color: #1d2129;

    ul,
    li {
      list-style-type: none;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 0;
    }

    .menu__submenu {
      position: relative;

      .icon-arrow-down {
        position: absolute;
        right: 0;
        top: 17px;
        display: block;
        width: 12px;
        height: 12px;
        background: url('@/assets/icons/arrow-down.svg') no-repeat;
        background-size: contain;
        margin-left: 8px;
      }

      .active {
        transform: rotate(180deg);
      }

      .menu__sub {
        padding-top: 15px;

        .menu__submenu-item {
          padding: 15px 0;
        }
      }
    }

    .menu__submenu,
    .menu__item {
      padding: 12px 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1d2129;
    }

    .menu__submenu:not(:last-child),
    .menu__item:not(:last-child) {
      border-bottom: 1px solid #f2f3f5;
    }

    .menu__submenu-item {
      font-size: 14px;
      line-height: 18px;
      color: #4e5969;
    }
  }
}
</style>
