<!--
 * @Author: luxiangqiang
 * @Date: 2023-04-25 15:54:36
 * @LastEditors: luxiangqiang
 * @LastEditTime: 2023-05-09 10:22:55
-->
<template>
  <div class="case">
    <!-- <div class="case-left">
      <div class="title">{{ $t('客户案例') }}</div>
      <nuxt-link
        class="case-item"
        v-for="(item, index) of menuList"
        :key="item.title"
        :to="{
          path: item.url ?? '/',
        }"
      >
        <span>{{ $t(item.title )}}</span>
        <img class="arrow-right" src="@/assets/icons/arrow-right-line.svg" alt="arrow-right" />
      </nuxt-link>
    </div> -->
    <div class="solution">
      <div class="title">{{ $t('解决方案') }}</div>
      <nuxt-link class="solution-item" v-for="item of solutionList" :key="item.title" :to="item.url">
        <span>{{ $t(item.title) }}</span>
        <img class="arrow-right" src="@/assets/icons/arrow-right-line.svg" alt="arrow-right" />
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IMenuItem } from '@/common/menu';

const solutionList = reactive([
  {
    title: '全球合规',
    url: '/solution/global-legitimate',
  },
  {
    title: '薪酬支付',
    url: '/solution/payroll',
  },
  {
    title: '海外招聘',
    url: '/product/oversea-recruitment',
  },
]);

const props = defineProps<{ menuList: IMenuItem[] }>();
</script>

<style scoped lang="less">
.case {
  position: absolute;
  // left: -246px;
  top: 42px;
  width: fit-content;
  color: #000;
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 24px 28px;
  box-sizing: border-box;
  display: flex;

  .case-left {
    width: 200px;

    .title {
      padding-bottom: 16px;
      border-bottom: 1px solid #e5e7eb;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1d2129;
    }

    .case-item {
      display: flex;
      justify-content: space-between;
      padding: 9px 8px;
      margin-left: -8px;
      font-size: 15px;
      line-height: 22px;
      color: #4e5969;
      font-weight: 400;
      text-decoration: none;

      &:hover {
        color: #165dff;
        background: #f5f8ff;

        .arrow-right {
          display: block;
          color: #165dff;
        }
      }

      .arrow-right {
        display: none;
        width: 20px;
        height: 20px;
      }
    }
  }

  .solution {
    // margin-left: 92px;
    width: 200px;

    .title {
      padding-bottom: 16px;
      border-bottom: 1px solid #e5e7eb;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1d2129;
    }

    .solution-item {
      display: flex;
      justify-content: space-between;
      padding: 9px 8px;
      margin-left: -8px;
      font-size: 15px;
      line-height: 22px;
      color: #4e5969;
      font-weight: 400;
      text-decoration: none;

      &:hover {
        color: #165dff;
        background: #f5f8ff;

        .arrow-right {
          display: block;
          color: #165dff;
        }
      }

      .arrow-right {
        display: none;
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
