<template>
  <div class="info-menu">
    <div class="info-menu-inner">
      <div class="menu-item" v-for="menu in menuList" :key="menu.name" @click="emits('onMenuClick', menu)">
        <div class="menu-title">{{ $t(menu.title) }}</div>
        <!-- <div v-if="list" class="list" @click.stop>
          <nuxt-link class="item-article" v-for="article in list?.[menu.name as keyof typeof list]" :key="article.id"
            :to="`/info/${menu.name}/${article.id}`">
            <span>{{ $t(article.attributes.title) }}</span>
            <icon-right class="icon" />
          </nuxt-link>
        </div> -->
        <!-- <div class="more">

          <icon-right style="font-size: 12px; color: #165DFF;" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IMenuItem } from '@/common/menu';
import { IArticle } from '@/@types/entities';
import { IList } from '~~/@types/cms';

const props = defineProps<{ menuList: IMenuItem[] }>();
const emits = defineEmits(['onMenuClick']);
const router = useRouter();
const list = ref<Record<string, { id: number; attributes: IArticle }[]> | null>(null);

// onMounted(async () => {
//   const response = await fetch('/api/article/menu-recommend');
//   const [
//     policyData,
//     newsData,
//     guideData
//   ] = await response.json() as IList<IArticle>[];
//   list.value = {
//     policy: policyData?.data.map(item => ({
//       ...item,
//       url: `/info/${item.attributes.category.data.attributes.name}/${item.id}`,
//     })),
//     news: newsData?.data.map(item => ({
//       ...item,
//       url: `/info/${item.attributes.category.data.attributes.name}/${item.id}`,
//     })),
//     guide: guideData?.data.map(item => ({
//       ...item,
//       url: `/info/${item.attributes.category.data.attributes.name}/${item.id}`,
//     }))
//   };
// });
</script>

<style lang="less" scoped>
.info-menu {
  position: absolute;
  left: -10%;
  top: 42px;
  // padding-top: 14px;

  .info-menu-inner {
    color: #000;
    background-color: #fff;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    // gap: 32px;
  }

  .menu-item {
    flex-shrink: 0;
    // width: 272px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 4px;


    &:hover {
      .menu-title {
        color: #165dff;
        background: #f5f8ff;
      }
    }

    .menu-title {
      padding: 15px 44px 15px 12px;
      white-space: nowrap;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1d2129;
      padding-bottom: 12px;
      // border-bottom: 1px solid #E5E7EB;
    }

    .list {
      margin-top: 12px;

      .item-article {
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        color: #4e5969;
        padding-left: 20px;
        box-sizing: border-box;
        background: url('@/assets/icons/article-icon-off.svg') no-repeat left center/14px 14px;
        display: flex;
        align-items: center;
        padding-right: 30px;
        text-decoration: none;

        span {
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .icon {
          display: none;
          font-size: 14px;
          color: #165dff;
          opacity: 0.4;
          margin-left: 8px;
        }

        &:hover {
          color: #165dff;
          background-color: #f5f8ff;
          background-image: url('@/assets/icons/article-icon-on.svg');

          .icon {
            display: block;
          }
        }
      }
    }

    .more {
      margin-top: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #165dff;
    }
  }
}
</style>
