<template>
  <div class="resource-menu">
    <div class="resource-menu-inner">
      <div class="global">
        <div class="title" @click="emits('onMenuClick', menuList[0])">
          <span>{{ $t('全球雇佣指南') }}</span>
          <div class="all-country">
            <span>{{ $t('全部国家') }}</span>
            <img class="arrow-right" src="@/assets/icons/arrow-right-line.svg" alt="arrow-right" />
          </div>
        </div>
        <div class="flag-list">
          <div
            class="img"
            v-for="countryIcon in countryFlagList"
            :key="countryIcon"
            :style="{ backgroundImage: `url(${countryIcon})` }"
          ></div>
          <div class="country">{{ $t('150+国家') }}</div>
          <div class="country-list" @click="handlerGoCountryList">
            <img src="@/assets/icons/hot.svg" alt="hot" />
            <span>{{ $t('出海国家热门榜') }}</span>
          </div>
        </div>
        <div class="select-country-wrapper" ref="popupContainer">
          <CountrySelectNoCreate
            v-model:targetCountry="targetCountry"
            ref="countrySelectNoCreate"
            :popupContainer="(popupContainer as HTMLElement)"
          />
          <a-button class="btn" type="primary" @click="handleToCountry">
            {{ $t('查看') }}
          </a-button>
        </div>
        <div class="hot-country-list">
          <div class="hot-title">{{ $t('热门') }}：</div>
          <template v-for="(hotC, i) in hotCountryList" :key="hotC.id">
            <nuxt-link class="country-name" :to="`/resource/global/${hotC.name}`">{{
              locale === 'zh-CN' ? hotC.cnName : hotC.name
            }}</nuxt-link>
            <a-divider v-if="i < hotCountryList.length - 1" direction="vertical" />
          </template>
        </div>
      </div>
      <div class="right-menu">
        <nuxt-link class="menu-item" v-for="menu in menuList.slice(1)" :key="menu.title" :to="menu.url">
          <div class="title" :style="{ backgroundImage: `url(${menu.iconUrl})` }">
            {{ $t(menu.title) }}
          </div>
          <icon-right class="icon" />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CountrySelectNoCreate from '@/components/CountrySelectNoCreate.vue';
import '@arco-design/web-vue/es/message/style/css.js';
import { ICountry } from '~~/@types/entities';
import { IMenuItem } from '@/common/menu';
import { IconRight } from '@arco-design/web-vue/es/icon';
import { useI18n } from 'vue-i18n';
const { locale } = useI18n();


type IHotCountryList = ICountry & { id: string; priority: number };

const props = defineProps<{ menuList: IMenuItem[] }>();
const emits = defineEmits(['onMenuClick']);
const router = useRouter();

const targetCountry = ref('');
const popupContainer = ref<HTMLElement | null>(null);
const countryList = inject<() => IHotCountryList[]>('countryList');

const { data: allCountryList } = await useAsyncData(async () => {
  const { data } = await useFetch<ICountry[]>('/api/resource/country/list', {
    method: 'GET',
  });
  return data.value;
});

const countrySort = computed(() => {
  if (!countryList) {
    return [];
  }
  return countryList()?.sort((a, b) => {
    return b.priority - a.priority;
  });
});

const countryFlagList = computed(() => {
  return (
    countrySort.value
      .slice(6, 11)
      .map(
        c =>
          `https://jkqj-assets-beijing.oss-cn-beijing.aliyuncs.com/global/flags_w320/${c.shortCode?.toLowerCase()}.jpg`
      ) || []
  );
});

const hotCountryList = computed(() => {
  return [
    {
      name: 'singapore',
      id: '8b05de309e810b927b09ba54f9a2dbab',
      cnName: '新加坡',
    },
    {
      name: 'malaysia',
      id: 'e739dd1f6fd0c348521590af1c419854',
      cnName: '马来西亚',
    },
    {
      name: 'mexico',
      id: '8a5512c8fc476c01a89f4cf71f09f2bc',
      cnName: '墨西哥',
    },
    {
      name: 'united-states',
      id: '5a11d8e5e64ad31299eb58d3cac59581',
      cnName: '美国',
    },
  ];
});

const countrySelectNoCreate = ref<typeof CountrySelectNoCreate | null>(null);
const handlerGoCountryList = () => {
  router.push(`/rank-list`);
};

const handleToCountry = () => {
  if (!targetCountry.value) {
    const targetName = countrySelectNoCreate.value?.getCurrentCarouselCnName();
    const res = countryList?.()?.find(c => c.cnName === targetName);
    if (res) {
      router.push(`/resource/global/${res.name}`);
      return;
    }
  }
  const res = countryList?.()?.find(c => c.cnName === targetCountry.value || c.enName === targetCountry.value);
  if (targetCountry.value && res) {
    router.push(`/resource/global/${res.name}`);
    return;
  }
  const hasCountry = allCountryList.value?.find(c => c.cnName === targetCountry.value);
  if (hasCountry) {
    useLeadsInfo('首页-获取国家工具包', 'contactUs', { targetCountry: targetCountry.value });
    return;
  }
  navigateTo(`/help-center/search?keyword=${targetCountry.value}`);
};
</script>

<style lang="less" scoped>
.resource-menu {
  position: absolute;
  left: -130px;
  top: 42px;
  padding-bottom: 180px;
  padding-top: 14px;

  .resource-menu-inner {
    width: fit-content;
    color: #000;
    background-color: #fff;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 24px 24px 23px;
    box-sizing: border-box;
    display: flex;
    gap: 16px;

    .global {
      width: 360px;
      padding: 20px 20px 15px 20px;
      background: linear-gradient(178.9deg, #d6f6fd -2.16%, #fdffff 32.56%);
      border: 1px solid #f2f3f5;
      border-radius: 4px;
      min-height: 130px;
      box-sizing: border-box;
      cursor: auto;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #1d2129;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
          color: #1d2129;
        }
      }

      .all-country {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        color: #4e5969;

        .arrow-right {
          cursor: pointer;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #4e5969;
          cursor: pointer;

          .icon {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .flag-list {
        display: flex;
        gap: 6px;
        align-items: center;
        margin-top: 12px;

        .country {
          font-size: 12px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #86909c;
        }

        .country-list {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 18px;
          color: #165dff;
          margin-left: auto;
          margin-right: 0;

          img {
            margin-right: 4px;
          }
        }

        .img {
          width: 18px;
          height: 18px;
          background: no-repeat center center/cover;
          border-radius: 50%;
        }
      }

      .select-country-wrapper {
        width: 100%;
        height: 40px;
        background: #ffffff;
        border: 1px solid #d6dce7;
        border-radius: 4px;
        margin-top: 34px;
        position: relative;
        box-sizing: border-box;

        :deep(.arco-input-wrapper) {
          height: 38px;
          background-color: #fff;
          padding-right: 70px;

          .arco-select-view-suffix {
            display: none;
          }

          .arco-select-view-input,
          .arco-input {
            font-size: 14px;
          }
        }

        :deep(.arco-carousel.country-carousel) {
          width: calc(100% - 80px);
          height: 40px;

          .country-carousel-item {
            line-height: 40px;
            font-size: 14px;
          }
        }

        .btn {
          position: absolute;
          width: 60px;
          height: 40px;
          right: -1px;
          top: -1px;
          border-radius: 0px 4px 4px 0px;
          font-weight: 500;
          font-size: 14px;
        }
      }

      .hot-country-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 16px;
        gap: 8px;

        .hot-title,
        .country-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #4e5969;
          text-decoration: none;
          text-transform: capitalize;
        }

        :deep(.arco-divider-vertical) {
          height: 8px;
          margin: 0;
        }
      }
    }

    .right-menu {
      width: 722px;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .menu-item {
        width: 230px;
        height: 58px;
        padding: 0 12px 0 14px;
        border: 1px solid #f2f3f5;
        border-radius: 4px;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 16px;
        color: #1d2129;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        text-decoration: none;

        .title {
          padding-left: 26px;
          box-sizing: border-box;
          background: no-repeat left center/20px 20px;
        }

        .icon {
          font-size: 18px;
          color: #86909c;
        }

        &:hover {
          background: #f5f8ff;
        }
      }
    }
  }
}
</style>
