<template>
  <div class="dropdown-menu-container">
    <div class="dropdown-menu">
      <div
        class="menu-item"
        :class="{ active: menu.name === activeName }"
        v-for="menu in transMenus"
        :key="menu.name"
        @click.stop="handleClickMenu(menu.name)"
      >
        {{ menu.title }}
        <icon-down class="icon" size="12" />
      </div>
    </div>
    <a-drawer
      class="dropdown-menu-drawer"
      :visible="drawer.visible"
      :header="false"
      :footer="false"
      @cancel="handleCancelDrawer"
      placement="top"
      height="auto"
      popup-container=".dropdown-menu-container"
      v-for="drawer in transMenus"
      :key="drawer.name"
    >
      <div v-if="['product', 'resource', 'about'].includes(activeName)" class="dropdown-menu-list">
        <nuxt-link
          class="dropdown-menu"
          :to="dropdownMenu.url"
          v-for="dropdownMenu in (drawer.children as IMenuItem[])"
          :key="dropdownMenu.name"
        >
          <div class="left">
            <a-image :src="dropdownMenu.iconUrl" width="20" height="20" />
            <div class="text">{{ dropdownMenu.title }}</div>
          </div>
          <icon-right style="color: #86909C;" />
        </nuxt-link>
      </div>
      <div v-else-if="activeName === 'info'" class="dropdown-menu-list-text">
        <nuxt-link
          class="dropdown-menu-text"
          :to="dropdownMenu.url"
          v-for="dropdownMenu in drawer.children" :key="dropdownMenu.name"
        >
          {{ dropdownMenu.title }}
        </nuxt-link>
      </div>
    </a-drawer>
  </div>
</template>

<script setup lang="ts">
import menus, { IMenuItem } from '@/common/menu';

const activeName = ref('');
const transMenus = ref(menus.filter(menu => ['product', 'resource', 'info', 'about'].includes(menu.name)).map(m => ({
  ...m,
  visible: false
})));

const timer = ref<any>();
function handleClickMenu(name: string) {
  if (timer.value) {
    return;
  }
  if (activeName.value === name) {
    handleCancelDrawer();
    activeName.value = '';
    return;
  }
  handleCancelDrawer();
  if (activeName.value) {
    timer.value = setTimeout(() => {
      transMenus.value.find(m => m.name === name)!.visible = true;
      timer.value = undefined;
      activeName.value = name;
    }, 300);
  } else {
    transMenus.value.find(m => m.name === name)!.visible = true;
    activeName.value = name;
  }
}

function handleCancelDrawer(isResetActiveName?: boolean) {
  transMenus.value = transMenus.value.map(m => ({
    ...m,
    visible: false
  }));
  if (isResetActiveName) {
    activeName.value = '';
  }
}
const router = useRouter();
router.afterEach(() => {
  handleCancelDrawer();
  activeName.value = '';
});

watch(transMenus, newVal => {
  if (newVal.some(n => n.visible)) {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = 'auto';
  }
}, { deep: true });

defineExpose({
  handleCancelDrawer,
  transMenus
});
</script>

<style lang="less" scoped>
@media screen and (min-width: 800px) {
  .dropdown-menu-container {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .dropdown-menu-container {
    width: 100vw;
    background-color: #5488FF;
    .dropdown-menu {
      box-sizing: border-box;
      padding: 0 10px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }
    .menu-item {
      display: flex;
      align-items: center;
      gap: 3px;
      padding: 7px 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #F7F8FA;
      -webkit-tap-highlight-color: transparent;
      &.active {
        background: #F5F9FF;
        border-radius: 16px;
        color: #165DFF;
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }
  :deep(.arco-drawer-container.dropdown-menu-drawer) {
    height: calc(100vh - 108px) !important;
    top: 108px !important;
    z-index: -1 !important;
    .arco-drawer {
      border-radius: 0 0 8px 8px !important;
      .arco-drawer-body {
        padding: 12px;
        .dropdown-menu-list {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 12px;
          .dropdown-menu {
            padding: 14px 16px;
            border: 1px solid #F2F3F5;
            border-radius: 4px;
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            -webkit-tap-highlight-color: transparent;
            .left {
              display: flex;
              align-items: center;
              gap: 6px;
              .arco-image-img {
                vertical-align: unset;
              }
              .text {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #1D2129;
              }
            }
          }
        }
        .dropdown-menu-list-text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .dropdown-menu-text {
            padding: 7px 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #1D2129;
            border: 1px solid #F2F3F5;
            border-radius: 4px;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
