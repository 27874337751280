import productOverseaEmployIcon from '@/assets/icons/product-oversea-employ-icon.svg';
import productPayrollIcon from '@/assets/icons/product-payroll-icon.svg';
import productPolyEmployIcon from '@/assets/icons/product-poly-employ-icon.svg';
import solutionGlobalLegitimateIcon from '@/assets/icons/solution-global-legitimate-icon.svg';
import resourceWageCalculatorIcon from '@/assets/icons/resource-wage-calculator-icon.svg';
import payrollIcon from '@/assets/icons/payroll-icon.svg';
import laborEmploymentRegulations from '@/assets/icons/labor-employment-regulations.svg';
import corporateTaxIcon from '@/assets/icons/corporate-tax-icon.svg';
import governmentDepartmentsRelevantInstitutionsIcon from '@/assets/icons/government-departments-relevant-institutions-icon.svg';
import banksInsuranceCompaniesIcon from '@/assets/icons/banks-insurance-companies-icon.svg';
import rankListIcon from '@/assets/icons/rank-list-icon.svg';
import companyInfoIcon from '@/assets/icons/company-info-icon.svg';
import competitionIcon from '@/assets/icons/competition-icon.svg';
import partnerProgramIcon from '@/assets/icons/partner-program-icon.svg';
import developmentCapabilityIcon from '@/assets/icons/development-capability-icon.svg';
export interface IMenuItem {
  title: string | any;
  name: string;
  children?: IMenuItem[];
  url?: string;
  iconUrl?: string
  menuSubTitle?: string
  menuDesc?: string | string[]
}


export default [
  {
    title: '产品中心',
    name: 'product',
    children: [
      {
        title: '海外雇佣',
        name: 'oversea-employ',
        url: '/product/oversea-employ',
        iconUrl: productOverseaEmployIcon,
        menuDesc: '无需注册海外雇佣实体，我们处理整个本地雇佣流程，负责合规性、工资和人力资源管理',
      },
      {
        title: '海外薪酬',
        name: 'payroll',
        url: '/product/payroll',
        iconUrl: 'https://video.reta-inc.com/image/default/EC0EEE6CE54B49F7A37942BC210A7397-6-2.jpg',
        menuDesc: '为海外企业和员工提供雇佣的法律保障。支持长期、项目制签约等方式',
      },
      {
        title: '海外灵活用工',
        name: 'poly-employ',
        url: '/product/poly-employ',
        iconUrl: 'https://video.reta-inc.com/image/default/53FD7587390647E19B6B3024B48A3C0E-6-2.jpg',
        menuDesc: '150+国家薪酬服务，简化支付流程，减少为支付团队薪酬而拼凑供应商和平台的时间',
      },
      {
        title: '海外招聘',
        name: 'oversea-recruitment',
        url: '/product/oversea-recruitment',
        iconUrl: 'https://video.reta-inc.com/image/default/76C957175DCE452C9B2E9C1F6839305D-6-2.jpg',
        menuDesc: '150+国家薪酬服务，简化支付流程，减少为支付团队薪酬而拼凑供应商和平台的时间',
      },
    ],
  },
  {
    title: '资源中心',
    name: 'resource',
    children: [
      {
        title: '全球雇佣指南',
        name: 'global',
        url: '/resource/global',
        iconUrl: 'https://video.reta-inc.com/image/default/CF112EEF0E634066A8147C6C69B8B771-6-2.png',
        children: [
          {
            title: '工具',
            name: 'tool',
            url: '/resource/global/tool',
          },
        ],
      },
      {
        title: '雇佣成本计算器',
        name: 'cost-calculator',
        url: '/resource/cost-calculator',
        iconUrl: 'https://video.reta-inc.com/image/default/5DA9FB54559240A89F19B40FDFDEB3FB-6-2.png'
      },
      {
        title: '员工工资计算器',
        name: 'wage-calculator',
        url: '/resource/wage-calculator',
        iconUrl: 'https://video.reta-inc.com/image/default/0A45888921D74E39A694685B49019598-6-2.png'
      },
      {
        title: '全球薪酬洞察',
        name: 'compensation-insight-tool',
        url: '/resource/compensation-insight-tool',
        iconUrl: productPayrollIcon
      },
      {
        title: '全球劳动法',
        name: 'labor-employment-regulations',
        url: '/resource/global/global/labor-employment-regulations',
        iconUrl: laborEmploymentRegulations
      },
      {
        title: '全球税收政策',
        name: 'corporate-tax',
        url: '/resource/global/global/corporate-tax',
        iconUrl: corporateTaxIcon
      },
      {
        title: '全球政府机构',
        name: 'government-departments-relevant-institutions',
        url: '/resource/global/global/government-departments-relevant-institutions',
        iconUrl: governmentDepartmentsRelevantInstitutionsIcon
      },
      {
        title: '全球银保机构',
        name: 'banks-insurance-companies',
        url: '/resource/global/global/banks-insurance-companies',
        iconUrl: banksInsuranceCompaniesIcon
      },
      // { title: '跨境电商选品工具', name: 'rank-list', url: '/rank-list', iconUrl: 'https://video.reta-inc.com/image/default/C6059321D7624DC6A92A431B379559E1-6-2.png' },
    ],
  },
  {
    title: '行业资讯',
    name: 'info',
    children: [
      { title: '海外动态', name: 'news', url: '/info/news' },
      { title: '出海攻略', name: 'guide', url: '/info/guide' },
      { title: '政策指南', name: 'policy', url: '/info/policy' },
    ],
  },
  {
    title: '解决方案',
    name: 'showcase',
    children: [
      { title: '新能源汽车', name: 'new-energy', url: '/showcase/new-energy' },
      { title: '游戏', name: 'game', url: '/showcase/game' },
      { title: '仓储物流', name: 'logistics', url: '/showcase/logistics' },
      { title: '金融科技', name: 'technology', url: '/showcase/technology' },
    ]
  },
  { title: '收费标准', name: 'charge', url: '/charge' },
  {
    title: '关于我们',
    name: 'about',
    children: [
      {
        title: '关于公司',
        name: 'company-info',
        url: '/about/company-info',
        iconUrl: companyInfoIcon
      },
      {
        title: '我们的优势',
        name: 'competition',
        url: '/about/competition',
        iconUrl: competitionIcon
      },
      {
        title: '合作伙伴计划',
        name: 'partner-program',
        url: '/about/partner-program',
        iconUrl: partnerProgramIcon
      },
      {
        title: '帮助中心',
        name: 'help-center',
        url: '/help-center',
        iconUrl: 'https://video.reta-inc.com/image/default/5ED5B33AF4C34A59967782A75A8B0AEB-6-2.png'
      },
      {
        title: '开放能力',
        name: 'development-capability',
        url: '/about/development-capability',
        iconUrl: developmentCapabilityIcon
      },
    ],
  },
];
