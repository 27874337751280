<!--
 * @Author: luxiangqiang
 * @Date: 2023-04-25 14:16:06
 * @LastEditors: luxiangqiang
 * @LastEditTime: 2023-05-08 18:44:40
-->
<template>
  <div class="product-menu">
    <div class="product-left">
      <div class="title">{{ $t('核心服务') }}</div>
      <div class="menu-wrapper">
        <nuxt-link class="menu-item" v-for="menu in menuList" :key="menu.name" :to="menu.url">
          <div class="content">
            <div class="icon" :style="{ backgroundImage: `url(${menu.iconUrl})` }"></div>
            <div class="menu-title">{{ $t(menu.title) }}</div>
          </div>
          <img class="arrow-right" src="@/assets/icons/arrow-right-line.svg" alt="arrow-right" />
        </nuxt-link>
      </div>
    </div>
    <!-- <div class="product-right">
      <div class="title">{{ $t('平台工具') }}</div>
      <nuxt-link class="product-item" v-for="item of tools" :key="item.title" :to="item.url">
        <span>{{ $t(item.title) }}</span>
        <img class="arrow-right" src="@/assets/icons/arrow-right-line.svg" alt="arrow-right" />
      </nuxt-link>
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { IMenuItem } from '@/common/menu';

const tools = reactive([
  {
    title: '全球人力SAAS平台',
    url: '/about/free-consultation?from=首页-产品中心-平台工具-全球人力SAAS平台&type=serviceConsulting'
  },
  {
    title: '在线合同签署平台',
    url: '/about/free-consultation?from=首页-产品中心-平台工具-在线合同签署平台&type=serviceConsulting'
  },
  {
    title: '跨境薪酬支付平台',
    url: '/about/free-consultation?from=首页-产品中心-平台工具-跨境薪酬支付平台&type=serviceConsulting'
  },
  {
    title: '候选人远程面试工具',
    url: '/about/free-consultation?from=首页-产品中心-平台工具-候选人远程面试工具&type=serviceConsulting'
  },
  {
    title: 'RPA自动化提效工具',
    url: '/about/free-consultation?from=首页-产品中心-平台工具-RPA自动化提效工具&type=serviceConsulting'
  },
]);

const props = defineProps<{ menuList: IMenuItem[] }>();
const emits = defineEmits(['onMenuClick']);
</script>

<style lang="less" scoped>
.product-menu {
  width: 428px;
  box-sizing: border-box;
  display: flex;
  position: absolute;
  left: 0px;
  top: 42px;
  color: #000;
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 24px 32px 16px 32px;
  box-sizing: border-box;
  display: flex;

  .product-left {
    width: 374px;

    .title {
      padding-bottom: 16px;
      border-bottom: 1px solid #E5E7EB;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1D2129;
    }

    .menu-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .menu-item {
        margin-top: 16px;
        width: 180px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 15px 22px;
        background: #FFFFFF;
        border: 1px solid #F2F3F5;
        border-radius: 4px;
        text-decoration: none;

        &:hover {
          background: #F5F8FF;
          border-radius: 4px;
        }

        .content {
          display: flex;

          .menu-title {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #1D2129;
            margin-left: 8px;
          }

          .icon {
            width: 20px;
            height: 20px;
            background: no-repeat center center/20px 20px;
          }
        }

        .arrow-right {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .product-right {
    width: 200px;
    margin-left: 80px;

    .title {
      padding-bottom: 16px;
      border-bottom: 1px solid #E5E7EB;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1D2129;
      margin-bottom: 16px;
    }

    .product-item {
      display: flex;
      justify-content: space-between;
      padding: 9px 8px;
      font-size: 15px;
      line-height: 22px;
      color: #4E5969;
      font-weight: 400;
      text-decoration: none;
      margin-left: -8px;

      &:hover {
        color: #165DFF;
        background: #F5F8FF;

        .arrow-right {
          display: block;
          color: #165DFF;
        }
      }

      .arrow-right {
        display: none;
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
